import { TableContainer, TableHead, TableRow } from "@mui/material";
import styled from "styled-components";

export const StyledTableContainer = styled(TableContainer)`
  max-height: 450px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.base};
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  @media (max-width: 1000px) {
    border-radius: 0;
  }
`;

export const StyledTableHead = styled(TableHead)`
  background-color: #f5f5f5;
  position: sticky;
  top: 0;
  z-index: 1;

  th {
    font-weight: 600;
    color: #333;
    padding: 12px 16px;
    background-color: #f5f5f5;
  }
`;

export const StyledTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #fafafa;
  }

  &:hover {
    background-color: #dddddd;
  }

  td {
    padding: 12px 16px;
  }
`; 