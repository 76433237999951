import { Box, Table, TableBody, TableCell, TableRow, IconButton } from "@mui/material";
import { DeliveryTimeIcon } from "components/DeliveryTimeIcon";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { IPunctualityOrder } from "interfaces/IPunctuality";
import { formatLateTime } from "../../utils/formatters";
import { getDeliveryStatus, getRowStatus } from "../../utils/status";
import { StyledTableContainer, StyledTableHead, StyledTableRow } from "./styles";
import { formatDate, formatTime } from "utils/dateFormat";

type SortField = 'orderId' | 'city' | 'attemptTime' | 'lateTime';
type SortOrder = 'asc' | 'desc';

interface TableProps {
  orders: IPunctualityOrder[];
  sortField: SortField;
  sortOrder: SortOrder;
  onSort: (field: SortField) => void;
}

const SortIcon = ({ field, currentField, currentOrder }: { field: SortField; currentField: SortField; currentOrder: SortOrder }) => {
  if (currentField !== field) {
    return <ArrowUpwardIcon sx={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '16px' }} />;
  }
  return currentOrder === 'asc' ?
    <ArrowUpwardIcon sx={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '16px' }} />
    : <ArrowDownwardIcon sx={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '16px' }} />;
};

export const PunctualityTable = ({ orders, sortField, sortOrder, onSort }: TableProps) => {
  const sortOrders = (orders: IPunctualityOrder[]) => {
    return [...orders].sort((a, b) => {
      let comparison = 0;

      switch (sortField) {
        case 'orderId':
          comparison = String(a.orderId).localeCompare(String(b.orderId));
          break;
        case 'city':
          comparison = String(a.city).localeCompare(String(b.city));
          break;
        case 'attemptTime':
          comparison = new Date(a.attemptTime).getTime() - new Date(b.attemptTime).getTime();
          break;
        case 'lateTime':
          comparison = a.lateTime - b.lateTime;
          break;
        default:
          comparison = 0;
      }

      return sortOrder === 'asc' ? comparison : -comparison;
    });
  };

  return (
    <StyledTableContainer>
      <Table>
        <StyledTableHead>
          <TableRow>
            <TableCell>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                ID do pedido
                <IconButton
                  size="small"
                  onClick={() => onSort('orderId')}
                  sx={{
                    opacity: { xs: 1, sm: sortField === 'orderId' ? 1 : 0 },
                    '&:hover': { opacity: 1 }
                  }}
                >
                  <SortIcon field="orderId" currentField={sortField} currentOrder={sortOrder} />
                </IconButton>
              </Box>
            </TableCell>
            <TableCell>Cliente</TableCell>
            <TableCell>Bairro</TableCell>
            <TableCell>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                Cidade
                <IconButton
                  size="small"
                  onClick={() => onSort('city')}
                  sx={{
                    opacity: { xs: 1, sm: sortField === 'city' ? 1 : 0 },
                    '&:hover': { opacity: 1 }
                  }}
                >
                  <SortIcon field="city" currentField={sortField} currentOrder={sortOrder} />
                </IconButton>
              </Box>
            </TableCell>
            <TableCell>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                Data de entrega
                <IconButton
                  size="small"
                  onClick={() => onSort('attemptTime')}
                  sx={{
                    opacity: { xs: 1, sm: sortField === 'attemptTime' ? 1 : 0 },
                    '&:hover': { opacity: 1 }
                  }}
                >
                  <SortIcon field="attemptTime" currentField={sortField} currentOrder={sortOrder} />
                </IconButton>
              </Box>
            </TableCell>
            <TableCell>Prazo</TableCell>
            <TableCell>Horário de entrega</TableCell>
            <TableCell>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                Atraso
                <IconButton
                  size="small"
                  onClick={() => onSort('lateTime')}
                  sx={{
                    opacity: { xs: 1, sm: sortField === 'lateTime' ? 1 : 0 },
                    '&:hover': { opacity: 1 }
                  }}
                >
                  <SortIcon field="lateTime" currentField={sortField} currentOrder={sortOrder} />
                </IconButton>
              </Box>
            </TableCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {sortOrders(orders).map((row: IPunctualityOrder, index: number) => (
            <StyledTableRow key={index} status={getRowStatus(row.lateTime)}>
              <TableCell>{row.orderId}</TableCell>
              <TableCell>{row.cnpjInt}</TableCell>
              <TableCell>{row.bairro}</TableCell>
              <TableCell>{row.city}</TableCell>
              <TableCell>{formatDate(row.attemptTime)}</TableCell>
              <TableCell>{formatTime(row.scheduledTo)}</TableCell>
              <TableCell>
                <Box display="flex" alignItems="center" gap={1}>
                  {formatTime(row.attemptTime)}
                  {' '}
                  <DeliveryTimeIcon timing={getDeliveryStatus(row.lateTime)} />
                </Box>
              </TableCell>
              <TableCell>{formatLateTime(row.lateTime)}</TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
}; 