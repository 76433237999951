import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { StyledDateRangePicker, SelectBack, Container } from './styles';
import { is, ptBR } from 'date-fns/locale';
import { addYears, endOfYear, startOfYear } from 'date-fns';
import { defaultTheme } from 'styles/theme/default';

interface IProps {
  open: boolean,
  dateRangeLimit?: number,
  isBoxReturn?: boolean,
  isBoxCount?: boolean,
  isDashboard?: boolean,
  position?: "left" | "right" | "below" | "center",
  setOpen: (x: boolean) => void,
  onChangeParams: (x: any) => void,
  onChangeParamsDate?: (x: any) => void,
}

const INITIAL_DATE = {
  startDate: new Date(),
  endDate: new Date(),
  key: 'selection'
}

export function Calendar({
  open,
  dateRangeLimit,
  position,
  isBoxReturn,
  isBoxCount,
  setOpen,
  onChangeParams,
  onChangeParamsDate,
  isDashboard
}: IProps) {
  const [selectedDate, setSelectedDate] = useState(INITIAL_DATE);
  const [minDate, setMinDate] = useState(endOfYear(addYears(new Date(), -3)))
  const [maxDate, setMaxDate] = useState(new Date())
  const [isRangeInvalid, setIsRangeInvalid] = useState(false)

  const formatDate = (date: any) => {
    return dayjs(date).format('YYYY-MM-DD');
  }

  const initialDate = formatDate(selectedDate.startDate)
  const finalDate = formatDate(selectedDate.endDate)
  const today = formatDate(new Date())

  const getReturnParams = (isToday: boolean) => {
    return isBoxCount ? {
      "startCountDate": isToday ? today : initialDate,
      "endCountDate": isToday ? today : finalDate
    } : isBoxReturn ? {
      "startDeliveryDate": isToday ? today : initialDate,
      "endDeliveryDate": isToday ? today : finalDate,
      "deliveryDate": isToday ? today : initialDate
    } : isDashboard ? {
      "startDate": isToday ? today : initialDate,
      "endDate": isToday ? today : finalDate
    } : {
      "minDate": isToday ? today : initialDate,
      "maxDate": isToday ? today : finalDate,
      "page": 1
    }
  }

  const fetchFilterDate = async () => {
    isBoxReturn || isDashboard ? onChangeParams(getReturnParams(false)) : onChangeParams({ "minDate": initialDate, "maxDate": finalDate, "page": 1 })
    onChangeParamsDate && onChangeParamsDate({ "startDate": initialDate, "endDate": finalDate })
    setOpen(false)
  };

  const clearParams = async () => {
    resetDate()
    isBoxReturn || isDashboard ? onChangeParams(getReturnParams(true)) : onChangeParams({ "minDate": today, "maxDate": today, "page": 1 })
    setSelectedDate(INITIAL_DATE)
  }

  const resetDate = () => {
    setMinDate(startOfYear(new Date()))
    setMaxDate(endOfYear(addYears(new Date(), 3)))
  }

  const isDateRangeGreaterThan14Days = (start: number, end: number) => {
    const millisecondsInOneDay = 24 * 60 * 60 * 1000;
    const differenceInMilliseconds = Math.abs(end - start);
    const differenceInDays = differenceInMilliseconds / millisecondsInOneDay;

    return differenceInDays >= (dateRangeLimit ?? 14);
  };

  useEffect(() => {
    if (dateRangeLimit) {
      const finalDate = selectedDate.endDate
      const initialDate = selectedDate.startDate

      setIsRangeInvalid(isDateRangeGreaterThan14Days(initialDate.getTime(), finalDate.getTime()))
    }
  }, [selectedDate])

  if (!open) return null

  return (
    <>
      <SelectBack className="popupBack" onClick={(e) => {
        e.stopPropagation()
        setOpen(false)
      }} />
      <Container className={`${position}`}>
        <StyledDateRangePicker
          onChange={(item: any) => setSelectedDate(item.selection)}
          moveRangeOnFirstSelection={false}
          months={1}
          ranges={[selectedDate]}
          direction="horizontal"
          locale={ptBR}
          minDate={minDate}
          maxDate={maxDate}
          rangeColors={[defaultTheme.colors['base']]}
        />
        <div className='filter'>
          <button className='limparFiltro' onClick={clearParams}>Limpar Filtro</button>
          <button className='aplicarFiltro' onClick={fetchFilterDate} disabled={isRangeInvalid}>Aplicar Filtro</button>
        </div>
      </Container>
    </>
  );
}
