import { TableContainer, TableHead, TableRow } from "@mui/material";
import styled from "styled-components";

export const StyledTableHead = styled(TableHead)`
  position: sticky;
  top: 0;
  background-color: #F4F4F4;
  z-index: 10;
`;

export const StyledTableContainer = styled(TableContainer)`
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  background-color: #fff;
  max-height: 450px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    overflow-x: auto;
    height: 600px;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.base};
    border-radius: 4px;
  }

  @media (max-width: 1000px) {
    border-radius: 0;
  }
`;

export const StyledTableRow = styled(TableRow) <{ status: 'early' | 'onTime' | 'late' | 'veryLate' }>`
  background-color: ${({ status }) => {
    switch (status) {
      case 'early':
        return '#E3F2FD';
      case 'onTime':
        return '#E8F5E9';
      case 'late':
        return '#FFF3E0';
      case 'veryLate':
        return '#FFEBEE';
      default:
        return 'transparent';
    }
  }};
  &:hover {
    filter: brightness(0.95);
  }
`;

