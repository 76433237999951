import NotFoundImage from "assets/notFoundImage.svg";
import { Message, Image, DataNotFoundContainer } from "./styles";

const NoDataMessage = ({ message }: { message: string }) => (
  <DataNotFoundContainer>
    <Image src={NotFoundImage} alt="No data found" />
    <Message variant="body2">{message}</Message>
  </DataNotFoundContainer>
);

export default NoDataMessage;
