import { Typography } from '@mui/material';
import { DashboardCardContainer, Value, IconContainer, Content, TextContainer } from './styles';

interface DashboardCardProps {
  title: string;
  value: string | number;
  icon: React.ElementType;
  iconColor?: string;
  iconTextColor?: string;
}

export const DashboardCard = ({ title, value, icon: Icon, iconColor, iconTextColor }: DashboardCardProps) => {
  return (
    <DashboardCardContainer borderColor={iconTextColor || "#6200ea"}>
      <Content>
        <IconContainer backgroundColor={iconColor || "#6200ea"}>
          <Icon size={24} color={iconTextColor || "white"} />
        </IconContainer>
        <TextContainer>
          <Typography variant="body2" color="textSecondary">{title}</Typography>
          <Value variant="h5">{value}</Value>
        </TextContainer>
      </Content>
    </DashboardCardContainer>
  );
};
