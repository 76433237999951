import { Typography } from "@mui/material";
import styled from "styled-components";

export const DashboardCardContainer = styled.div<{ borderColor: string }>`
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 320px;
  min-height: 100px;
  background-color: #fff;
  border-left: 6px solid ${(props) => props.borderColor};

  @media (max-width: 1000px) {
    width: 100%;
    min-height: 50px;
    max-width: none;
    height: 80px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    h5 {
      font-size: 16px;
      font-weight: 500;
    }
  }

  @media (max-width: 1350px) and (min-width: 769px) {
    h5 {
      font-size: 18px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
`;

export const IconContainer = styled.div<{ backgroundColor: string }>`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${(props) => props.backgroundColor};
  padding: 10px;
`;

export const Value = styled(Typography)`
  font-weight: 700;
  color: black;
  font-size: 1.25rem;
`;
