import { StyledPageLoader } from "./styles";
import LargeLogoMaracuja from 'assets/largeLogo.svg'

export default function PageLoader({ height }: { height: string }) {
  return (
    <StyledPageLoader height={height}>
      <img className="rotate-image" src={LargeLogoMaracuja} alt="Logo Frexco Maracujá" />
      <span>
        Aguarde, estamos carregando os dados...
      </span>
    </StyledPageLoader>
  );
}
