import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { StyledTableHead } from "../../styles";
import NoDataMessage from "components/DataNotFound";
import { Skeleton } from "@mui/material";
import { DriverName } from "../../styles";

interface ComplaintsTableProps {
  data: any[];
  loading: boolean;
  searchParams: URLSearchParams;
}

export const ComplaintsTable = ({ data, loading, searchParams }: ComplaintsTableProps) => {
  const navigate = useNavigate();

  if (loading) {
    return <Skeleton variant="rectangular" width="100%" height={585} />;
  }

  if (data.length === 0) {
    return <NoDataMessage message="Nenhum dado de quebras disponível para o período selecionado" />;
  }

  return (
    <Table>
      <StyledTableHead>
        <TableRow>
          <TableCell>Posição</TableCell>
          <TableCell>Nome do Motorista</TableCell>
          <TableCell>Total de Itens</TableCell>
          <TableCell>Quebras</TableCell>
          <TableCell>Quebras (%)</TableCell>
        </TableRow>
      </StyledTableHead>
      <TableBody>
        {data?.map((complaint, index) => (
          <TableRow key={index}>
            <TableCell>{complaint?.position}</TableCell>
            <TableCell>
              <DriverName
                onClick={() => {
                  const newParams = new URLSearchParams(searchParams);
                  newParams.set("search", complaint.driverName);
                  navigate(`/driver-complaint?${newParams.toString()}`);
                }}
              >
                {complaint?.driverName}
              </DriverName>
            </TableCell>
            <TableCell>{complaint?.totalItems}</TableCell>
            <TableCell>{complaint?.totalComplaints}</TableCell>
            <TableCell>{complaint?.complaintPercentage}%</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}; 