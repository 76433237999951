import { Box, Typography } from "@mui/material";
import { IPunctualityResponse } from "interfaces/IPunctuality";

interface DriverSummaryProps {
  driver: IPunctualityResponse;
  isMobile?: boolean;
}

export const DriverSummary = ({ driver, isMobile = false }: DriverSummaryProps) => {
  const content = (
    <>
      <Typography variant="body2">
        <b>Pontualidade:</b> {driver.punctuality.toFixed(2)}%
      </Typography>
      <Typography variant="body2">
        <b>Total de Pedidos:</b> {driver.totalOrders < 10 ? `0${driver.totalOrders}` : driver.totalOrders}
      </Typography>
      <Typography variant="body2">
        <b>Total de Atrasos:</b> {" "}
        {driver.totalOrders - driver.onTimeOrders < 10 ? `0${driver.totalOrders - driver.onTimeOrders}` : driver.totalOrders - driver.onTimeOrders}
      </Typography>
    </>
  );

  if (isMobile) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          mb: 3,
          p: 2,
          bgcolor: 'background.paper',
          borderRadius: 1
        }}
      >
        {content}
      </Box>
    );
  }

  return (
    <Box
      mr={4}
      display="flex"
      flexDirection={{ xs: "column", md: "row" }}
      gap={{ xs: 1, md: 4 }}
    >
      {content}
    </Box>
  );
}; 