import styled from 'styled-components'

export const FilterSelectContainer = styled.div`
    position: absolute;
    top: 40px;
    left: 20vw;
    width: 273px;
    display: flex;
    align-items: center;
    z-index: 200;
    padding: 24px;
    cursor: default;
    background: #FFFFFF;
    border-radius: 8px;
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.12));

    .checked {
        display: inline-block;
        background-color:#89BD23;
    }

    .list {
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
    }

    .entry {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        height: 40px;
        padding-right: 12px;

        :hover {
            background-color: #F0F0F0;
        }

        .disabled {
            cursor: not-allowed;
        }
    }

    .filterSection {
        &:not(:first-child) {
            margin-top: 15px;
            padding-top: 15px;
            border-top: 1px solid #eee;
        }

        h4 {
            font-size: 15px;
            font-weight: 500;
            color: #333;
            margin: 2px 0;
        }
    }

    .entryName {
        font-size: 0.9rem;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        text-align: left;
        background: none;
        border: none;
        width: 100%;
        color: black;
        margin-left: 5px;
    }

    .entryName.disabled {
        color: #BDBDBD;
    }

    @media screen and (max-width:769px){
        width: 265px;
        top: 48px;
        left: 7vw;
        input {
          width: 100%;
        }

        .header {
            margin-bottom: 0;
            margin-top: 0;
        }

        ::before {
            visibility: hidden;
        }
    }
`;

export const SelectBack = styled.div`
    cursor: default;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 190;
    width: 100%;
`
