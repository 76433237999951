import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

export const PageContent = styled.div`
  width: 100%;
`

export const Container = styled.div`
  height: 100vh;

  .pageContent {
    width: 100%;
  }

`

export const SidebarContainer = styled.div`
  width: 72px;
`

export const MainContainer = styled.div`

  .pagination{
    display: flex;
    justify-content: center;
  }

  .MuiPagination-ul {
    display: flex;
    margin-top: 43px;
    margin-bottom: 50px;
    width: 400px;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 63px;
  }

  .MuiButtonBase-root {
    position: relative;
    width: 32px;
    height: 32px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-size: 14px;
    line-height: 16.5%;
    font-weight: 400;
    color: #6E8247;
  }

  .infos {
    margin-bottom: 28px;

    h3 {
      color: #353535;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 1.5rem;
      line-height: 29px;
    }
  }

  .input, .buttonPlus {
    height: 100%;
    position: relative;
  }

  .deliveryCardHeader {
    display: flex;
    width: 100%;
    height: 32px;
    margin-bottom: 8px;
    padding-left: 3%;
    align-items: center;

    color: ${defaultTheme.colors['gray-300']};

    .driverName {
      width: 30%;
    }
    .route {
      width: 15%;
    }
    .model {
      width: 15%;
    }
    .deliveries {
      width: 40%;
    }
  }

  .deliveryContainer {
    display: flex;
    flex-direction: column;
    padding: 12px 0;
    gap: 8px;
    border-radius: 12px;
  }

  .infos{
    margin-bottom: 0;
    h3{
      font-size: 1.5rem;
    }
  }

  @media screen and (max-width: 769px) {
    .mainCard{
      padding: 18px 10px 18px 10px;
    }
    .deliveryCardHeader {
    .driverName{
      margin-left: 30px;
      width: 29%;
    }
    .route{
      margin-left: 40px;
      width: 20%;
    }
    .model{
      display: none;
    }
    .deliveries{
      margin-left: 47px;
      width: 45%;
    }
    .delivery-amount{
      margin: 100px;
    }
  }
}

`

export const Line = styled.tr`
  width: 100%;
  height: 48px;

  .contentTable {
    padding-left: 3%;
  }

  .bodyLine:nth-child(2n) {
    background-color: #F5F5F5;
  }
`
