import React from "react";
import { ButtonProps as MuiButtonProps } from "@mui/material/Button";
import { enVariant, enSize, StyledButton } from "./styles";
interface IProps extends Omit<MuiButtonProps, "variant" | "size"> {
  variant?: keyof typeof enVariant;
  size?: keyof typeof enSize;
  children: React.ReactNode;
}

export function Button({
  variant,
  size = "medium",
  children,
  ...rest
}: IProps) {
  return (
    <StyledButton {...rest} variantBtn={variant} size={size}>
      {children}
    </StyledButton>
  );
}
