export const getDeliveryStatus = (lateTime: number) => {
  if (lateTime === 0) return 1;
  if (lateTime < 0 && lateTime >= -30) return 2;
  if (lateTime < -30) return 3;
  return 1;
};

export const getRowStatus = (lateTime: number) => {
  if (lateTime === 0) return 'onTime';
  if (lateTime < 0 && lateTime >= -30) return 'late';
  if (lateTime < -30) return 'veryLate';
  return 'early';
}; 