import { styled } from "@mui/material/styles";
import { ButtonBase } from "@mui/material";
import { defaultTheme } from "styles/theme/default";

export enum enVariant {
  primary = "primary",
}

export enum enSize {
  small = "small",
  medium = "medium",
  large = "large",
}

interface IButton {
  variantBtn?: keyof typeof enVariant;
  size?: keyof typeof enSize;
}

const sizes = {
  [enSize.small]: {
    fontSize: "0.75rem",
    padding: "4px 8px",
    height: "32px",
  },
  [enSize.medium]: {
    fontSize: "1rem",
    padding: "8px 16px",
    height: "40px",
  },
  [enSize.large]: {
    fontSize: "1rem",
    padding: "12px 24px",
    height: "48px",
  },
};

export const StyledButton = styled(ButtonBase, {
  shouldForwardProp: (prop: string) => prop !== "variantBtn" && prop !== "size",
}) <IButton>`
  ${({ theme, size = enSize.medium }) => `
    ${sizes[size] ? `
      font-size: ${sizes[size].fontSize};
      padding: ${sizes[size].padding};
      height: ${sizes[size].height};
    ` : ''}
    font-weight: 500;
    text-transform: none;
    font-family: ${theme.typography.fontFamily};
    transition: all 0.4s ease;
    overflow: hidden;
    position: relative;
    background: ${defaultTheme.colors.base};
    border-radius: 6px;
    color: #fff;
    box-shadow: 0px 0px 2px rgba(53, 53, 53, 0.14), 0px 2px 2px rgba(53, 53, 53, 0.12), 0px 1px 3px rgba(53, 53, 53, 0.2);

    &:hover {
      filter: brightness(1.05);
    }

    &:disabled {
      background-color: #cfcece;
      color: #a3a3a3;
    }

    @media (max-width: 768px) {
      height: 2.625rem !important;
    }

    .MuiTouchRipple-root {
      color: rgba(255, 255, 255, 0.3);
    }
  `}
`;
