import styled from 'styled-components';
import { Pagination } from '@mui/material';

export const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: center;

  .MuiPagination-ul {
    display: flex;
    margin: 20px 0;
    padding: 8px 16px;
    justify-content: center;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    border-radius: 30px;
    gap: 4px;
  }

  .MuiPaginationItem-root {
    margin: 0;
    min-width: 32px;
    height: 32px;
    border-radius: 4px;
    font-size: 14px;

    &.Mui-selected {
      background-color: ${({ theme }) => theme.colors.base};
      color: #fff;
      font-weight: 500;

      &:hover {
        background-color: ${({ theme }) => theme.colors.base};
      }
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  .MuiPaginationItem-previousNext {
    color: rgba(0, 0, 0, 0.54);
  }

  @media (max-width: 768px) {
    .MuiPagination-ul {
      padding: 6px 12px;
      gap: 2px;
    }

    .MuiPaginationItem-root {
      min-width: 28px;
      height: 28px;
      font-size: 13px;
    }
  }
`; 