import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
export const dateFormat = (dataString: string | null) => {
  if (dataString === null) {
    return 0;
  }

  const dateParts = dataString.split("-");

  if (dateParts.length !== 3) {
    return 0;
  }

  const [year, month, day] = dateParts;
  const formattedDate = format(new Date(`${month}/${day}/${year}`), 'dd/MM/yyyy');

  return formattedDate;
}

export const dateFormatWithHour = (dataString: string | null): string | number => {
  if (!dataString) {
    return 0;
  }

  try {
    const parsedDate = parseISO(dataString);
    const formattedDate = format(parsedDate, 'dd/MM/yyyy HH:mm');

    return formattedDate;
  } catch (error) {
    console.error("Erro ao formatar a data:", error);
    return 0;
  }
};

export const formatDate = (date: string) => {
  return format(new Date(date), "dd/MM/yyyy", { locale: ptBR });
};

export const formatTime = (date: string) => {
  return format(new Date(date), "HH:mm", { locale: ptBR });
};
