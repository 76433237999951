import dayjs from "dayjs";
import { FilterSelectContainer, SelectBack } from "./styles";
import { useEffect, useState } from "react";
import { RadioGroup } from "@mui/material";
import arrowFilter from 'assets/arrowFilter.svg';
import { Calendar } from "components/Calendar";
import CustomRadioButton from "components/RadioButton";

interface IDashboardFilterProps {
  open: boolean;
  setOpen: (x: boolean) => void;
  onChangeParams: (x: any) => void;
  loading: boolean;
}

const today = new Date();
const last7Days = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
const last15Days = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 15);
const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());

const formatDate = (date: Date) => dayjs(date).format("YYYY-MM-DD");

const initialEntries = [
  { id: 1, name: "Hoje", query: formatDate(today) },
  { id: 2, name: "Último mês", query: formatDate(lastMonth) },
  { id: 3, name: "Últimos 15 dias", query: formatDate(last15Days) },
  { id: 4, name: "Últimos 7 dias", query: formatDate(last7Days) },
];

export function FilterDashboard({ open, setOpen, onChangeParams, loading }: IDashboardFilterProps) {
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [dateSelect, setDateSelect] = useState(false);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = event.target.value;
    setSelectedDate(newDate);
    setDateSelect(false);
  };

  useEffect(() => {
    if (selectedDate === "") {
      return;
    }
    onChangeParams({ startDate: selectedDate });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  const display = open ? "initial" : "none";

  return (
    <>
      <SelectBack
        className="popupBack"
        onClick={(e) => {
          e.stopPropagation();
          setOpen(false);
        }}
        style={{ display }}
      />
      <FilterSelectContainer style={{ display }}>
        <RadioGroup value={selectedDate} onChange={handleRadioChange}>
          {initialEntries.map((item) => (
            <CustomRadioButton
              key={item.id}
              text={item.name}
              value={item.query}
              checked={selectedDate === item.query}
              handleChange={handleRadioChange}
              disabled={loading}
            />
          ))}
        </RadioGroup>

        <div
          className={`entry ${loading ? "disabled" : ""}`}
          onClick={() => {
            if (!loading) {
              setDateSelect(true);
            }
          }}
        >
          <button disabled={loading} type="button" className={`entryName ${loading ? "disabled" : ""}`}>
            Selecionar data
          </button>
          <img src={arrowFilter} alt="icon arrow filter" />
        </div>

        <Calendar
          dateRangeLimit={30}
          position="right"
          onChangeParams={(params) => {
            setSelectedDate("");
            onChangeParams(params);
          }}
          open={dateSelect}
          setOpen={setDateSelect}
          isDashboard
        />
      </FilterSelectContainer>
    </>
  );
}
