import styled from 'styled-components';
import { DateRangePicker } from 'react-date-range';
import { defaultTheme } from 'styles/theme/default';

export const Container = styled.div`
  position: absolute;
  top: 20%;
  left: 103%;
  width: 335px;
  height: 432px;
  display: flex;
  flex-direction: column;
  z-index: 200;

  cursor: default;

  background: #FFFFFF;
  border-radius: 8px;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.12));

  &.left {
    right: 103%;
  }

  &.below {
    left: 280px;
    top: 40px;
  }

  &.center {
    left: 0;
    right: 0;
    margin: auto;
  }

  .filter {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;

    button {
      font-size: 0.95;
      font-weight: 500;
      border-radius: 12px;
      width: 40%;
      height: 2rem;
      background: ${defaultTheme.colors['white-100']};
      border-radius: 12px;

      font-family: "Roboto", sans-serif;
      border: none;

      transition: all 100ms;

      :disabled {
        color: ${defaultTheme.colors['gray-200']}
      }

      :hover:disabled {
        background: ${defaultTheme.colors['white-table']};
        cursor: default;
      }
    }
  }

  .aplicarFiltro {
    color: ${defaultTheme.colors['base-dark']};

    :hover {
      background: ${defaultTheme.colors['base-light']};
    }
  }

  .limparFiltro {
    color: ${defaultTheme.colors['danger']};

    :hover {
      background-color: #ffccd1;
    }
  }

  @media screen and (max-width: 915px) {
    position: fixed;
    left: 50%;
    top: 265%;
    transform: translate(-50%, -50%);
    &.below {
      left: 50%;
      top: 60%;
      transform: translate(-50%, -50%);
    };
    &.center {
      left: 50%;
      top: 60%;
      transform: translate(-50%, -50%);
  };
    &.left {
        left: 50%;
        top: 60%;
        transform: translate(-50%, -50%);
    };
    &.right {
        left: 50%;
        top: 170%;
        transform: translate(-50%, -50%);
    }
}`

export const StyledDateRangePicker = styled(DateRangePicker)`
  position: relative;
  height: 355px;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  .rdrDefinedRangesWrapper {
    display: none;
  }

  .rdrDateDisplayWrapper {
    display: none;
    width: 100%;
    text-align: center;
  }

  .rdrDateDisplayItem {
    display: inline-block;
    margin: 0 10px;
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
  }

  .rdrDefinedRangesWrapper .rdrDefinedRange.selected {
    background-color: #8bc34a;
  }

  .rdrWeekDay {
    margin-right: 2px;
  }
`

export const SelectBack = styled.div`
  cursor: default;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 190;
`
