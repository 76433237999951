import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const Container = styled(Box)<{ isMobile: boolean }>(({ theme, isMobile }) => ({
  display: 'flex',
  flexDirection: isMobile ? 'column' : 'row',
  gap: isMobile ? theme.spacing(2) : theme.spacing(4),
  marginRight: isMobile ? 0 : theme.spacing(4),
  marginBottom: isMobile ? theme.spacing(3) : 0,
  padding: isMobile ? theme.spacing(2) : 0,
  backgroundColor: isMobile ? theme.palette.background.paper : 'transparent',
  borderRadius: isMobile ? theme.shape.borderRadius : 0,
}));

export const SummaryItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(0.5),
})); 