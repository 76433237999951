import React from "react";
import { AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Container, StyledMainAccordion, StyledAccordionDetails } from "./styles";
import { NoDataMessage } from "components/NoDataMessage/styles";

interface CustomAccordionProps<T> {
  data: T[];
  renderSummary: (item: T) => React.ReactNode;
  children: (item: T) => React.ReactNode;
}

export const CustomAccordion = <T,>({ data, renderSummary, children }: CustomAccordionProps<T>) => {
  return (
    <Container>
      {data.map((item, index) => (
        <StyledMainAccordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel-content-${index}`}
            id={`panel-header-${index}`}
          >
            {renderSummary(item)}
          </AccordionSummary>
          <StyledAccordionDetails>
            {children(item)}
          </StyledAccordionDetails>
        </StyledMainAccordion>
      ))}
    </Container>
  );
};
