import { Skeleton, Typography, IconButton } from "@mui/material";
import { Header } from "components/Header";
import { PageContainer } from "components/PageContainer";
import SidebarComponent from "components/Sidebar";
import { SidebarContainer } from "pages/Home/styles";
import { MdPendingActions, MdOutlineBrokenImage } from "react-icons/md";

import {
  CardsSide,
  CardsTop,
  Container,
  DashboardContainer,
  DashboardHeader,
  StyledTableContainer,
  TableHeader,
  TablesAndCardsWrapper,
  TablesContainer,
  HeaderBox
} from "./styles";
import { FaCheckCircle, FaClipboardList, FaClock, FaExclamationTriangle, FaTimesCircle } from "react-icons/fa";
import { IoFilter } from "react-icons/io5";
import { DashboardCard } from "./Components/Cards";
import { IDashboard } from "interfaces/IDashboard";
import { useCallback, useEffect, useMemo, useState } from "react";
import { dashboardService } from "services/dashboardService";
import { dateToday } from "utils/dateToday";
import { toast } from "react-toastify";
import { FilterDashboard } from "./Components/FilterDashboard";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "components/MuiButton";
import { SortButton } from "./Components/SortButton";
import { PunctualityTable } from "./Components/PunctualityTable";
import { ComplaintsTable } from "./Components/ComplaintsTable";

export const Dashboard = () => {
  const [data, setData] = useState<IDashboard>({} as IDashboard);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(() => {
    const startDateFromUrl = searchParams.get("startDate");
    const endDateFromUrl = searchParams.get("endDate");
    return {
      startDate: startDateFromUrl || dateToday(),
      endDate: endDateFromUrl || dateToday(),
    };
  });
  const [sortPunctuality, setSortPunctuality] = useState(true);
  const [sortComplaints, setSortComplaints] = useState(true);
  const [openFilterPopup, setOpenFilterPopup] = useState(false);
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    try {
      if (!params.startDate) {
        return;
      }
      setLoading(true);
      const response = await dashboardService.getDashboardData(params);
      if (response.errors.length) {
        throw new Error();
      }
      setData(response);
    } catch {
      toast.error("Erro ao buscar dados do dashboard");
    } finally {
      setLoading(false);
    }
  }, [params]);

  const onChangeParams = (newParams: typeof params) => {
    setParams(newParams);

    const searchParamsObj: Record<string, string> = {};
    if (newParams?.startDate) {
      searchParamsObj.startDate = newParams.startDate;
    }
    if (newParams?.endDate) {
      searchParamsObj.endDate = newParams.endDate;
    }
    setSearchParams(searchParamsObj);
  };

  const sortData = <T,>(data: T[], key: keyof T, ascending: boolean): T[] => {
    return [...data].sort((a, b) => {
      return ascending ? (a[key] as number) - (b[key] as number) : (b[key] as number) - (a[key] as number);
    });
  };

  const sortedPunctualityData = useMemo(() => {
    return data.punctualityData ? sortData(data.punctualityData, "position", sortPunctuality) : [];
  }, [data.punctualityData, sortPunctuality]);

  const sortedComplaints = useMemo(() => {
    return data.complaintsData ? sortData(data.complaintsData, "position", sortComplaints) : [];
  }, [data.complaintsData, sortComplaints]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Container>
      <div className="pageContent">
        <Header />
        <SidebarContainer>
          <SidebarComponent />
        </SidebarContainer>
        <PageContainer>
          <FilterDashboard
            loading={loading}
            onChangeParams={onChangeParams}
            open={openFilterPopup}
            setOpen={setOpenFilterPopup}
          />
          <DashboardContainer>
            <DashboardHeader>
              <h3>Dashboard</h3>
              <IconButton onClick={() => setOpenFilterPopup(true)}>
                <IoFilter size={24} />
              </IconButton>
            </DashboardHeader>
            <div className="dashboard-content">
              <CardsTop>
                {loading ? (
                  [...Array(4)].map((_, index) => (
                    <Skeleton
                      key={index}
                      variant="rectangular"
                      width="100%"
                      height={window.innerWidth <= 768 ? 80 : 100}
                      style={{ borderRadius: 12 }}
                    />
                  ))
                ) : (
                  <>
                    <DashboardCard
                      title="Pedidos"
                      value={`${data?.orderMetrics?.completedOrders} / ${data?.orderMetrics?.totalOrders}`}
                      icon={FaClipboardList}
                      iconColor="#BBDEFB"
                      iconTextColor="#1976D2"
                    />
                    <DashboardCard
                      title="Pedidos Cancelados"
                      value={data?.orderMetrics?.canceledOrders}
                      icon={FaTimesCircle}
                      iconColor="#FFCDD2"
                      iconTextColor="#C62828"
                    />
                    <DashboardCard
                      title="Pedidos Pendentes"
                      value={data?.orderMetrics?.pendingOrders}
                      icon={MdPendingActions}
                      iconColor="#FFF9C4"
                      iconTextColor="#F9A825"
                    />
                    <DashboardCard
                      title="Total de Quebras"
                      value={data?.totalComplaints}
                      icon={MdOutlineBrokenImage}
                      iconColor="#FFE0B2"
                      iconTextColor="#EF6C00"
                    />
                  </>
                )}
              </CardsTop>

              <TablesAndCardsWrapper>
                <TablesContainer>
                  <TableHeader>
                    <HeaderBox>
                      <Typography variant="h6">Pontualidade dos Motoristas</Typography>
                      {data.punctualityData && data.punctualityData.length > 0 && (
                        <SortButton
                          isAscending={sortPunctuality}
                          onChange={() => setSortPunctuality((prevState) => !prevState)}
                          disabled={loading}
                          title={
                            sortPunctuality
                              ? "Ordenar: Menor Pontualidade"
                              : "Ordenar: Maior Pontualidade"
                          }
                        />
                      )}
                    </HeaderBox>
                    {data.punctualityData && data.punctualityData.length > 0 && (
                      <Button
                        variant="primary"
                        onClick={() => navigate(`/driver-punctuality?${searchParams.toString()}`)}
                      >
                        Ver detalhes
                      </Button>
                    )}
                  </TableHeader>
                  <StyledTableContainer className={sortedPunctualityData.length === 0 ? "centered" : ""}>
                    <PunctualityTable
                      data={sortedPunctualityData}
                      loading={loading}
                      searchParams={searchParams}
                    />
                  </StyledTableContainer>

                  <TableHeader>
                    <HeaderBox>
                      <Typography variant="h6">Quebras dos motoristas</Typography>
                      {data.complaintsData && data.complaintsData.length > 0 && (
                        <SortButton
                          isAscending={sortComplaints}
                          onChange={() => setSortComplaints((prevState) => !prevState)}
                          disabled={loading}
                          title={
                            sortComplaints
                              ? "Ordenar: Maior Percentual de Quebras"
                              : "Ordenar: Menor Percentual de Quebras"
                          }
                        />
                      )}
                    </HeaderBox>
                    {data.complaintsData && data.complaintsData.length > 0 && (
                      <Button
                        variant="primary"
                        onClick={() => navigate(`/driver-complaint?${searchParams.toString()}`)}
                      >
                        Ver detalhes
                      </Button>
                    )}
                  </TableHeader>
                  <StyledTableContainer className={sortedComplaints.length === 0 ? "centered" : ""}>
                    <ComplaintsTable
                      data={sortedComplaints}
                      loading={loading}
                      searchParams={searchParams}
                    />
                  </StyledTableContainer>
                </TablesContainer>
                <CardsSide>
                  {loading ? (
                    [...Array(3)].map((_, index) => (
                      <Skeleton
                        key={index}
                        variant="rectangular"
                        width="100%"
                        height={window.innerWidth <= 768 ? 80 : 100}
                        style={{ borderRadius: 12 }}
                      />
                    ))
                  ) : (
                    <>
                      <>
                        <DashboardCard
                          title="Atrasos"
                          value={`${data?.punctualityMetrics?.totalDelays} (${data?.punctualityMetrics?.totalDelayPercentage?.toFixed(2)}%)`}
                          icon={FaExclamationTriangle}
                          iconColor="#FFCDD2"
                          iconTextColor="#C62828"
                        />
                        <DashboardCard
                          title="Pontualidade SLA"
                          value={`${data?.punctualityMetrics?.slaOnTime?.toFixed(2)}%`}
                          icon={FaCheckCircle}
                          iconColor="#C8E6C9"
                          iconTextColor="#2E7D32"
                        />
                        <DashboardCard
                          title="Pontualidade SLA (60 min)"
                          value={`${data?.punctualityMetrics?.sla60min?.toFixed(2)}%`}
                          icon={FaClock}
                          iconColor="#D1C4E9"
                          iconTextColor="#512DA8"
                        />
                      </>
                    </>
                  )}
                </CardsSide>
              </TablesAndCardsWrapper>
              <div className="spacer" style={{ height: 32 }} />
            </div>
          </DashboardContainer>
        </PageContainer>
      </div>
    </Container>
  );
};
