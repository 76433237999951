import { Box, Table, TableBody, TableCell, TableRow, IconButton } from "@mui/material";
import { StyledTableContainer, StyledTableHead, StyledTableRow } from "./styles";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { IComplaint } from "interfaces/IComplaints";
import { dateFormatWithHour } from "utils/dateFormat";

type SortField = 'orderId' | 'created' | 'reason' | 'productName';
type SortOrder = 'asc' | 'desc';

interface TableProps {
  complaints: IComplaint[];
  sortField: SortField;
  sortOrder: SortOrder;
  onSort: (field: SortField) => void;
}

const SortIcon = ({ field, currentField, currentOrder }: { field: SortField; currentField: SortField; currentOrder: SortOrder }) => {
  if (currentField !== field) {
    return <ArrowUpwardIcon sx={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '16px' }} />;
  }
  return currentOrder === 'asc' ?
    <ArrowUpwardIcon sx={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '16px' }} />
    : <ArrowDownwardIcon sx={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '16px' }} />;
};

export const ComplaintsTable = ({ complaints, sortField, sortOrder, onSort }: TableProps) => {
  const sortComplaints = (complaints: IComplaint[]) => {
    return [...complaints].sort((a, b) => {
      let comparison = 0;

      switch (sortField) {
        case 'orderId':
          comparison = String(a.orderId).localeCompare(String(b.orderId));
          break;
        case 'created':
          comparison = new Date(a.created).getTime() - new Date(b.created).getTime();
          break;
        case 'reason':
          comparison = String(a.reason).localeCompare(String(b.reason));
          break;
        default:
          comparison = 0;
      }

      return sortOrder === 'asc' ? comparison : -comparison;
    });
  };

  return (
    <StyledTableContainer>
      <Table>
        <StyledTableHead>
          <TableRow>
            <TableCell>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                ID do pedido
                <IconButton
                  size="small"
                  onClick={() => onSort('orderId')}
                  sx={{
                    opacity: { xs: 1, sm: sortField === 'orderId' ? 1 : 0 },
                    '&:hover': { opacity: 1 }
                  }}
                >
                  <SortIcon field="orderId" currentField={sortField} currentOrder={sortOrder} />
                </IconButton>
              </Box>
            </TableCell>
            <TableCell>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                Data
                <IconButton
                  size="small"
                  onClick={() => onSort('created')}
                  sx={{
                    opacity: { xs: 1, sm: sortField === 'created' ? 1 : 0 },
                    '&:hover': { opacity: 1 }
                  }}
                >
                  <SortIcon field="created" currentField={sortField} currentOrder={sortOrder} />
                </IconButton>
              </Box>
            </TableCell>
            <TableCell>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                Motivo
                <IconButton
                  size="small"
                  onClick={() => onSort('reason')}
                  sx={{
                    opacity: { xs: 1, sm: sortField === 'reason' ? 1 : 0 },
                    '&:hover': { opacity: 1 }
                  }}
                >
                  <SortIcon field="reason" currentField={sortField} currentOrder={sortOrder} />
                </IconButton>
              </Box>
            </TableCell>
            <TableCell>Responsável</TableCell>
            <TableCell>Produto</TableCell>
            <TableCell>Observação</TableCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {sortComplaints(complaints).map((complaint: IComplaint, index: number) => (
            <StyledTableRow key={index}>
              <TableCell>{complaint?.orderId}</TableCell>
              <TableCell>{dateFormatWithHour(complaint?.created)}</TableCell>
              <TableCell>{complaint?.reason}</TableCell>
              <TableCell>{complaint?.responsible}</TableCell>
              <TableCell>{complaint?.productName}</TableCell>
              <TableCell>{complaint?.observation || '-'}</TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
}; 