import { Accordion, AccordionDetails } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0,0,0,0.05);
  overflow: hidden;
  width: 100%;

  & > * + * {
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }
`;

export const StyledMainAccordion = styled(Accordion)`
  padding: 0;
  background-color: white;
  transition: all 0.2s ease-in-out;
  box-shadow: none;
  margin: 0 !important;
  border-radius: 0;

  &:first-child {
    border-radius: 8px 8px 0 0;
  }

  &:last-child {
    border-radius: 0 0 8px 8px;
  }

  &:only-child {
    border-radius: 8px;
  }

  &:hover {
    background-color: #f3f8e9;
  }

  & .MuiAccordionSummary-root {
    padding: 1rem;
    min-height: 48px;
  }

  & .MuiAccordionSummary-root.Mui-expanded {
    background-color: #e5ecd1;
    min-height: 48px;
  }

  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    background-color: #e5ecd1;
  }

  & .MuiCollapse-root {
    border-top: none;
    border-bottom: none;
  }

  & .MuiAccordionDetails-root {
    border-top: none;
  }

  &.Mui-expanded {
    padding: 0;
    background-color: white;
    margin: 0 !important;
  }

  &::before {
    display: none;
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 16px;
  background-color: white;
  border-top: none;
`;