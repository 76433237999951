export const formatLateTime = (lateTime: number) => {
  if (lateTime === 0) return 'No horário';
  if (lateTime < 0) {
    const minutes = Math.abs(Math.round(lateTime));
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (hours > 0) {
      return `${hours}h${remainingMinutes > 0 ? `${remainingMinutes}min` : ''} atrasado`;
    }
    return `${minutes}min atrasado`;
  }
  return `${Math.round(lateTime)}min adiantado`;
}; 