import { Typography } from "@mui/material";
import styled from "styled-components";

export const DataNotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
`;

export const Image = styled.img`
  width: 100%;
  max-width: 300px;
  height: auto;
`;

export const Message = styled(Typography)`
  margin-top: 10px;
`;
