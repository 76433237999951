import dayjs from "dayjs";
import { useState } from "react";
import { RadioGroup } from "@mui/material";
import arrowFilter from 'assets/arrowFilter.svg';
import { Calendar } from "components/Calendar";
import CustomRadioButton from "components/RadioButton";
import { dateToday } from "utils/dateToday";
import { FilterSelectContainer, SelectBack } from "./styles";

interface IProps {
  open: boolean;
  setOpen: (x: boolean) => void;
  onChangeParams: (x: any) => void;
  loading?: boolean;
  params: any;
}

const today = new Date();
const last7Days = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
const last15Days = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 15);
const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());

const formatDate = (date: Date) => dayjs(date).format("YYYY-MM-DD");

const initialDateEntries = [
  { id: 1, name: "Hoje", query: formatDate(today) },
  { id: 2, name: "Último mês", query: formatDate(lastMonth) },
  { id: 3, name: "Últimos 15 dias", query: formatDate(last15Days) },
  { id: 4, name: "Últimos 7 dias", query: formatDate(last7Days) },
];

const orderEntries = [
  { id: 1, name: "Maior quebra (%)", query: "desc" },
  { id: 2, name: "Menor quebra (%)", query: "asc" },
];

export function FilterComplaints({ open, setOpen, onChangeParams, loading = false, params }: IProps) {
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [selectedOrder, setSelectedOrder] = useState<string>("desc");
  const [dateSelect, setDateSelect] = useState(false);

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = event.target.value;
    setSelectedDate(newDate);
    setDateSelect(false);
    onChangeParams({
      ...params,
      startDate: newDate,
      endDate: dateToday(),
      page: 1
    });
  };

  const handleOrderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newOrder = event.target.value;
    setSelectedOrder(newOrder);
    onChangeParams({ ...params, order: newOrder, page: 1 });
  };

  const display = open ? "initial" : "none";

  return (
    <>
      <SelectBack
        className="popupBack"
        onClick={(e) => {
          e.stopPropagation();
          setOpen(false);
        }}
        style={{ display }}
      />
      <FilterSelectContainer style={{ display }}>
        <div className="filterSection">
          <h4>Período</h4>
          <RadioGroup value={selectedDate} onChange={handleDateChange}>
            {initialDateEntries.map((item) => (
              <CustomRadioButton
                key={item.id}
                text={item.name}
                value={item.query}
                checked={selectedDate === item.query}
                handleChange={handleDateChange}
                disabled={loading}
                size="small"
              />
            ))}
          </RadioGroup>

          <div
            className={`entry ${loading ? "disabled" : ""}`}
            onClick={() => {
              if (!loading) {
                setDateSelect(true);
              }
            }}
          >
            <button disabled={loading} type="button" className={`entryName ${loading ? "disabled" : ""}`}>
              Selecionar data
            </button>
            <img src={arrowFilter} alt="icon arrow filter" />
          </div>
        </div>
        <div className="filterSection">
          <h4>Ordenação</h4>
          <RadioGroup value={selectedOrder} onChange={handleOrderChange}>
            {orderEntries.map((item) => (
              <CustomRadioButton
                key={item.id}
                text={item.name}
                value={item.query}
                checked={selectedOrder === item.query}
                handleChange={handleOrderChange}
                disabled={loading}
                size="small"
              />
            ))}
          </RadioGroup>
        </div>
        <Calendar
          dateRangeLimit={30}
          position="right"
          onChangeParams={(calendarParams) => {
            setSelectedDate("");
            onChangeParams({ ...params, ...calendarParams, page: 1 });
          }}
          open={dateSelect}
          setOpen={setDateSelect}
          isDashboard
        />
      </FilterSelectContainer>
    </>
  );
} 