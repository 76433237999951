import styled from "styled-components";

export const HeaderWrapper = styled.div`
    position: fixed;
    width: 100%;
    z-index: 1000;
    height: 64px;
    display: flex;
    align-items: center;
    background-color: #FFF;

    .sidebarToggle {
        display: none;
        position: absolute;
        left: 6.6vw;
        width: 32px;
        height: 32px;

        svg {
            width: 32px;
            height: 32px
        }
    }

    .sidebarToggle button {
        position: relative;
        border: none;
        width: 100%;
        height: 100%;
    }

    .logoWrapper {
        display: flex;
        align-items: center;
        margin-left: 18px
    }

    .logoWrapper img {
        width: 28px;
    }
    .logoName {
        font-size: 1rem;
    }
    .userWrapper {
        position: absolute;
        width: 150px;
        height: 32px;
        right: 7vw;
        top: 16px;
        background-color: #FFF;
        border: none;
        font-size: 1rem;
        display: flex;
        align-items: center;
    }

    .avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        background-color: #89BD23;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        color: #FFF;
        margin-right: 10px;
    }

    @media screen and (max-width: 769px){
        .logoWrapper {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 115px;
        }

        .sidebarToggle {
            display: block;
        }

        .userWrapper {
            right: 6vw;
            width: fit-content;
        }

        .userWrapper .name {
            display: none;
        }
    }

`
