import { PaginationProps as MuiPaginationProps } from '@mui/material';
import { StyledPagination } from './styles';

interface CustomPaginationProps extends Omit<MuiPaginationProps, 'onChange'> {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

export const CustomPagination = ({ currentPage, totalPages, onPageChange, ...rest }: CustomPaginationProps) => {
  return (
    <StyledPagination
      shape="rounded"
      count={totalPages}
      page={currentPage}
      onChange={(_, value) => onPageChange(value)}
      {...rest}
    />
  );
}; 