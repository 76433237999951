import { IconButton } from "@mui/material";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { SortTooltip } from "../../styles";

interface SortButtonProps {
  isAscending: boolean;
  onChange: () => void;
  disabled: boolean;
  title: string;
}

export const SortButton = ({ isAscending, onChange, disabled, title }: SortButtonProps) => {
  return (
    <SortTooltip title={title} dir="right">
      <IconButton onClick={onChange} disabled={disabled}>
        <SwapVertIcon
          style={{
            transform: isAscending ? "rotate(180deg)" : "rotate(0deg)",
            transition: "transform 0.3s ease-in-out",
          }}
        />
      </IconButton>
    </SortTooltip>
  );
}; 