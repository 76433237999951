import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

interface RadioLabelProps {
  justifyContent?: string;
}

export const RadioLabel = styled.div<RadioLabelProps>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justifyContent || "space-between"};
  font-size: 10px;
  width: 100%;
  margin-left: 5px;

  .radio-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .MuiFormControlLabel-root {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0;
    font-size: 12px;
  }

  .MuiTypography-root {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
  }

  .MuiRadio-root {
    &.Mui-checked {
      color: ${defaultTheme.colors["base"]};
    }

    &.Mui-disabled {
      color: ${defaultTheme.colors["gray-100"]};
    }

    &.MuiSvgIcon-root {
      font-size: 12px;
    }
  }
`;

