import { baseURL } from 'settings';

import { api as apiService, ApiService } from './api'
import { IDashboard, IDashboardParams } from 'interfaces/IDashboard';
import { IPunctualityParams, IPunctualityResponse } from 'interfaces/IPunctuality';
import { DashboardPaginationProps } from 'interfaces/IPagination';
import { IComplaintsResponse } from 'interfaces/IComplaints';

class DashboardService {
  constructor(private readonly api: ApiService, private readonly baseURL: string) { }

  public getDashboardData = async (params: IDashboardParams): Promise<IDashboard> => {
    return await this.api.get(`${this.baseURL}/v1/dashboard/`, { params });
  };

  public getPunctualityData = async (params: IPunctualityParams): Promise<DashboardPaginationProps<IPunctualityResponse>> => {
    return await this.api.get(`${this.baseURL}/v1/dashboard/punctuality`, { params });
  }

  public getComplaintsData = async (params: IDashboardParams): Promise<DashboardPaginationProps<IComplaintsResponse>> => {
    return await this.api.get(`${this.baseURL}/v1/dashboard/complaint`, { params });
  }
}

export const dashboardService = new DashboardService(apiService, baseURL)
