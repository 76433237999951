import styled from "styled-components";
import { defaultTheme } from "styles/theme/default";

export const NoDataMessage = styled.div<{isPopup?: boolean, ht?: string}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${props => props.isPopup ? '32vh' : props.ht ? props.ht : '55vh'};
  h3 {
    text-align: center;
    padding: 20px;
    color: ${defaultTheme.colors['gray-300']};
    font-size: 17px;
  }
  p {
    text-align: center;
    color: ${defaultTheme.colors['gray-200']};;
    font-size: 16px;
  }
`;
