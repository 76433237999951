import { Typography } from "@mui/material";
import { IComplaintsResponse } from "interfaces/IComplaints";
import { SummaryItem, Container } from "./styles";


interface DriverSummaryProps {
  driver: IComplaintsResponse;
  isMobile?: boolean;
}

export const DriverSummary = ({ driver, isMobile = false }: DriverSummaryProps) => {
  const getComplaintReasonCount = (reason: string) => {
    return driver.complaints.filter(complaint => complaint.reason === reason).length;
  };

  const notDeliveredCount = getComplaintReasonCount('Não entregue');
  const damagedCount = getComplaintReasonCount('Amassado/Quebrado');
  const wrongDeliveryCount = getComplaintReasonCount('Entregue errado');

  return (
    <Container isMobile={isMobile}>
      <SummaryItem>
        <Typography variant="body2" color="text.secondary">
          Total de Quebras
        </Typography>
        <Typography variant="h6" fontSize={15}>
          {driver.totalComplaints}
        </Typography>
      </SummaryItem>
      <SummaryItem>
        <Typography variant="body2" color="text.secondary">
          Não Entregues
        </Typography>
        <Typography variant="h6" fontSize={15}>
          {notDeliveredCount}
        </Typography>
      </SummaryItem>
      <SummaryItem>
        <Typography variant="body2" color="text.secondary">
          Amassados/Quebrados
        </Typography>
        <Typography variant="h6" fontSize={15}>
          {damagedCount}
        </Typography>
      </SummaryItem>
      <SummaryItem>
        <Typography variant="body2" color="text.secondary">
          Entregas Erradas
        </Typography>
        <Typography variant="h6" fontSize={15}>
          {wrongDeliveryCount}
        </Typography>
      </SummaryItem>
    </Container>
  );
}; 