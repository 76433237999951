import styled from 'styled-components'
import { defaultTheme } from 'styles/theme/default'

interface IPopupStyles {
  aside?: boolean,
  isObservation?: boolean,
  titleStyles?: {
    align?: 'center | left | right',
    pl?: string,
    pr?: string
  },
}

export const PopupContainer = styled.div<IPopupStyles>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.46);
    z-index: 1001;

    .titleObs {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #757575;
    }

    .descObs {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: #353535;
    }

    .imagePopup {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10000;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
    }


    .popup {
        position: fixed;
        width: fit-content;
        height: ${({ aside }) => aside ? '100vh' : 'fit-content'};
        top: 0;
        bottom: 0;
        ${({ aside }) => aside ? {
          width: '45vw',
          right: 0,
          borderRadius: '16px 0 0 16px',
        } : {
          left: 0,
          right: 0,
          margin: 'auto',
          borderRadius: '16px'
        }};
        background-color: #FFF;
        z-index: 120;
    }
    .closeButton {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 23px;
      padding: 20px;
      cursor: pointer;
    }

    .tablePopup {
        position: relative;
        display: flex;
        gap: 24px;
    }

    .popupContent.tablePopup {
        max-height: 400px;
        overflow-y: auto;
    }

    .scrollableTable {
        overflow-y: auto;
    }

    .popup > .header {
        display: flex;
        height: 64px;
        gap: 24px;
        align-items: center;
        justify-content: left;
    }

    .tableRows td {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 140%;
        color: ${defaultTheme.colors['gray-300']};
        border: none;
        padding: 15px auto;
    }

    .tableRows:nth-child(2n) {
        background-color: ${defaultTheme.colors['white-table']};
    }

    .popupContent {
        font-size: 0.8rem;
        margin: 0 20px 20px 20px;
    }

    #popupForm {
        display: flex;
        gap: 48px;
    }

    .col1, .col2 {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .col1 {
        order: -1;

        &.full {
            width: 100%;
        }
    }

    .section {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .sectionHeader {
            position: sticky;
            top: 0;
            height: 48px;
            background-color: ${defaultTheme.colors['white-100']};
            z-index: 200;
        }
    }

    .form {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 12px;

        &.scrollable {
            position: relative;
            overflow-y: auto;
            padding: 8px;
        }

        &.full {
            justify-content: space-between;
        }

        &.vertical {
            flex-direction: column;
            gap: 8px;

            .formName {
                width: 100%;
            }
        }
    }

    .form .formName {
        font-size: 0.87rem;
        color: ${defaultTheme.colors['gray-300']};
        width: 90px;
    }

    .btnContainer {
        width: 100%;
        height: fit-content;
        padding: 0 32px 32px;
        display: flex;
        justify-content: space-between;
        gap: 14px;
        margin-bottom: 0;
        margin-top: 20px;

        Button {
        width: 160px;
        height: 40px;

        border-radius: 16px;

        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        color: #FFF;

        justify-content: center;

        text-align: center;
    }
    }

    .cancelSaveBtnContainer {
        display: flex;
        gap: 14px;
    }

    .ExitButton {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 64px;
        width: auto;
    }

    .ExitButton > button{
        padding: 5px;
    }


    input:not([type="checkbox"]) {
        padding: 8px 12px;
        color: ${defaultTheme.colors['black']};
        font-size: 0.8rem;
        font-weight: 400;
        height: 32px;
        border-radius: 8px;

        &:read-only {
            background-color: transparent;
            cursor: default;
        }
    }

    .obsInput {
        border-radius: 12px;
        background: ${defaultTheme.colors['base-input']};
        border: none;

        color: black;
        font-size: 1rem;
        padding: 20px;
        &::placeholder {
            color: ${defaultTheme.colors['base-label']};
        }
        resize: none;
        width: 33em;
        height: 5em;
    }

    .drivers {
        color: ${defaultTheme.colors['gray-200']}
    }

    .smallInput {
        width: 90px;
    }

    .medInput {
        width: 132px;
    }

    .bigInput {
        width: 265px;
    }

    .teamInput {
        width: 241px;
    }

    button.deleteBtn {
        background-color: ${defaultTheme.colors['danger']};
        :disabled {
            background-color: ${defaultTheme.colors['gray-200']};
        }
    }

    button.cancelBtn {
        border: 1px solid ${defaultTheme.colors['base-dark']};
        background-color: #FFF;
        color: ${defaultTheme.colors['base-dark']};
    }

    .driverSelectContainer {
        width: 61%;
        display: flex;
        gap: 12px;

        button {
            height: 32px;
            border-radius: 8px;
            font-size: 0.8rem;
        }
    }

    .removeBtn, .selectBtn {
        padding: 0 12px;
        background-color: ${defaultTheme.colors['base-input']};
        color: black
    }

    .deactivateBtn {
        width: 40%;
        padding-left: 16px;
        padding-right: 16px;
        background-color: ${defaultTheme.colors['danger']};
    }

    .dropdownBtn {
        position: relative;
        color: #404040;
        font-size: 0.8rem;
        width: 130px;
        height: 28px;
        /* background: #FFEEDA; */
        border-radius: 30px;
        border: none;
        padding: 6px 12px;
        text-align: left;
        font-family: 'Roboto', sans-serif;
        display: flex;
        align-items: center;
        justify-content: space-between;

        :disabled {
            color: #FFF;
            cursor: not-allowed;
        }

        &.large {
            width: 241px;
        }
    }

    .popupTitle {
        padding-left: ${props => props.titleStyles?.pl || '20px'};
        padding-right: ${props => props.titleStyles?.pr || '0'};
        text-align: ${props => props.titleStyles?.align || 'left'};
        font-size: 1.4rem;
        font-family: 'Inter' sans-serif;
        line-height: 22px;
        user-select: none;
    }

    .section.horizontal {
        display: flex;
        flex-direction: row;
    }

    .sectionTitle {
        width: 100%;
        font-size: 1.2rem;
        font-family: 'Inter';
        font-weight: 500;
    }

    .sectionDesc {
        font-family: Inter;
        font-size: 1rem;
        font-weight: 500;
        color: ${defaultTheme.colors['gray-300']}

    }

    .changeColor {
        position: relative;
        width: 64px;
    }

    .changeColorBtn {
        position: relative;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        border: none;
    }

    .errorMsg {
        /* font-size: 0.8rem; */
        color: ${defaultTheme.colors['danger']};
    }

    .popup.obsPopup {
        min-width: 31vw;
        min-height: 300px;
    }

    .popup.obsPopup .popupContent {
        margin-bottom: 0;
    }

    .popup.obsPopup .form textarea {
        min-width: 100%;
        height: 150px;
    }

    .popup.obsPopup .btnContainer {
        height: fit-content;
        flex-direction: column;
        margin-top: 0;
        margin-bottom: 0;
        .cancelSaveBtnContainer {
            flex-direction: column;
        }
        .saveBtn, .cancelBtn {
            width: 100%;
        }
    }

    @media screen and (max-width: 769px){
        .closeButton {
            padding: 29px 20px;
            font-size: 23px;
        }

        .popup {
            flex-direction: column;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 85vw;
            min-height: 30vh;

            ${({ aside }) => aside && {
                width: '100vw',
                height: 'fit-content',
                borderRadius: '16px 16px 0 0',
                marginBottom: 0,
            }}

            &.expand {
                width: 90vw;
                min-height: 70vh;

                .popupContent {
                    max-height: 80vh;
                }
            }
        }

        .tablePopup {
            flex-direction: column;
        }

        .popup > .header {
            height: 48px;
            margin-top: 16px;
            justify-content: center;
            flex-direction: column;
        }

        .popupTitle {
            padding-left: 0;
            font-size: 1.2rem;
        }

        .popupContent {
            max-height: 40vh;
            overflow-y: scroll;
            flex-direction: column;
            margin: 0 20px 20px 20px;

            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
            ::-webkit-scrollbar {
                display: none; /* Chrome */
            }

            ${({ aside }) => aside && {
              maxHeight: '67vh'
            }}
        }

        .section .sectionDesc {
            width: 100%;
            text-align: center;
        }

        .section.horizontal {
            flex-direction: column;
        }

        #popupForm {
            flex-direction: column;
            min-width: 100%;
        }

        .col1, .col2 {
            order: 0;
        }

        .btnContainer {
            height: fit-content;
            flex-direction: column;
            align-items: center;
            padding-bottom: 0px;
            margin-bottom: 32px;
        }

        .btnContainer Button {
            width: 100%;
        }

        .deleteBtnContainer {
            margin-right: 0;
        }

        .cancelSaveBtnContainer {
            order: -1;
            display: flex;
            flex-direction: column;
            gap: 14px;
            width: 100%;
        }

        .deleteBtn {
            margin-right: 0;
        }

        .popup.obsPopup .btnContainer {
            margin-top: 24px;
            .cancelSaveBtnContainer {
                margin-bottom: 12px;
                width: 100%;
            }
        }
    }
`
