import { IconButton, TableContainer, TableHead, Box, Tooltip, Typography } from "@mui/material";
import styled from "styled-components";
import SwapVertIcon from '@mui/icons-material/SwapVert';

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;

  h3 {
    color: #353535;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 29px;

    @media (max-width: 1000px) {
      font-size: 17px;
    }
  }

  @media (max-width: 1000px) {
    padding: 0;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const CardsTop = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  gap: 38px;
  width: 100%;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    gap: 14px;
    margin-bottom: 0;
  }
`;

export const TablesAndCardsWrapper = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 1000px) {
    flex-direction: column-reverse;
  }
`;

export const TablesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 75%;
  overflow-y: auto;
  padding-right: 5px;

  @media (max-width: 1000px) {
    width: 100%;
    padding-right: 0;
  }
`;

export const CardsSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 25%;
  min-width: 200px;
  padding-left: 30px;

  @media (max-width: 1000px) {
    width: 100%;
    min-width: 0;
    padding-left: 0;
    align-items: center;
    gap: 14px;
    margin-top: 14px;
    margin-bottom: 15px;
  }
`;

export const Container = styled.div`
  height: 100vh;

  .pageContent {
    width: 100%;
  }
`;

export const StyledIconButton = styled(IconButton)`
  background-color: #f5f5f5;
  border-radius: 12px;
  padding: 8px;
`;

export const DashboardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  padding: 16px 0;

  @media (max-width: 1000px) {
    justify-content: space-between;
  }
`;

export const StyledTableHead = styled(TableHead)`
  position: sticky;
  top: 0;
  background-color: #f3f8e6;
  z-index: 10;
`;

export const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  border-radius: 8px 8px 0 0;
  
  h6 {
    color: #353535;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;

    h6 {
      font-size: 17px;
    }
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  background-color: #fff;
  height: 585px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    overflow-x: auto;
    height: 600px;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.base};
    border-radius: 4px;
  }

  &.centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const RotatingIconButton = styled(IconButton) <{ rotated: boolean }>`
  transform: ${props => props.rotated ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: transform 0.3s ease-in-out;
`;

interface SwapVertIconProps {
  showTop10Punctuality: boolean;
}

export const StyledSwapVertIcon = styled(SwapVertIcon) <SwapVertIconProps>`
  transform: ${({ showTop10Punctuality }) =>
    showTop10Punctuality ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: transform 0.3s ease-in-out;
`;

export const HeaderBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  width: 100%;

  @media (min-width: 768px) {
    width: fit-content;
  }
`;

export const SortTooltip = styled(Tooltip)`
  .MuiTooltip-tooltip {
    background-color: #333;
    font-size: 14px;
  }
`;

export const DriverName = styled(Typography).attrs({
  component: "span"
})`
  cursor: pointer;
  font-size: 0.875rem !important;
  &:hover {
    text-decoration: underline;
  }
`; 