import { useCallback, useEffect, useState } from "react";
import { Header } from "components/Header";
import SidebarComponent from "components/Sidebar";
import { PageContainer } from "components/PageContainer";
import { Toolbar } from "components/Toolbar";
import { Box, Typography } from "@mui/material";
import { CustomAccordion } from "components/accordion";
import { dashboardService } from "services/dashboardService";
import { ComplaintsTable } from "./components/Table";
import { DriverSummary } from "./components/DriverSummary";
import { Container, MainContainer, SidebarContainer } from "./styles"
import { toast } from "react-toastify";
import PageLoader from "components/Table/PageLoader";
import { NoDataMessage } from "components/NoDataMessage/styles";
import { DashboardPaginationProps } from "interfaces/IPagination";
import { CustomPagination } from "components/CustomPagination";
import { dateToday } from "utils/dateToday";
import { useSearchParams } from "react-router-dom";
import { IComplaintsResponse } from "interfaces/IComplaints";

type SortField = 'orderId' | 'created' | 'reason' | 'productName';
type SortOrder = 'asc' | 'desc';

export const Complaints = () => {
  const [complaintsData, setComplaintsData] = useState<DashboardPaginationProps<IComplaintsResponse>>(
    {} as DashboardPaginationProps<IComplaintsResponse>
  );
  const [loading, setLoading] = useState(false);
  const [sortField, setSortField] = useState<SortField>('created');
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc');
  const [searchParams, setSearchParams] = useSearchParams();
  const [params, setParams] = useState(() => {
    const startDate = searchParams.get("startDate") || dateToday();
    const endDate = searchParams.get("endDate") || dateToday();
    const page = Number(searchParams.get("page")) || 1;
    const order = searchParams.get("order") || "desc";
    const search = searchParams.get("search") || "";
    const perPage = Number(searchParams.get("perPage")) || 10;

    return {
      startDate,
      endDate,
      page,
      perPage,
      order,
      search
    }
  });

  const onChangeParams = (newParams: typeof params) => {
    setParams(newParams);
    setSearchParams(newParams as any);
  };

  const fetchComplaintsData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await dashboardService.getComplaintsData(params);
      setComplaintsData(response);
    } catch (error) {
      toast.error('Erro ao buscar dados de reclamações');
    } finally {
      setLoading(false);
    }
  }, [params]);

  useEffect(() => {
    fetchComplaintsData();
  }, [fetchComplaintsData]);

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortOrder('asc');
    }
  };

  return (
    <Container>
      <div className="pageContent">
        <Header />
        <SidebarContainer>
          <SidebarComponent />
        </SidebarContainer>
        <PageContainer>
          <MainContainer>
            <div className='infos'>
              <h3>Quebras</h3>
              <Toolbar
                params={params}
                paramState={onChangeParams}
                loadingState={loading}
                placeholder="Motorista"
                page="complaints"
              />
            </div>
            {loading ? (
              <PageLoader height='55vh' />
            ) : (
              <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
                {complaintsData?.items?.length > 0 ? (
                  <>
                    <CustomAccordion
                      data={complaintsData.items}
                      renderSummary={(driver) => (
                        <Box display="flex" alignItems="center" width="100%" justifyContent={"space-between"}>
                          <Typography
                            variant="h6"
                            sx={{
                              fontSize: { xs: '1rem', sm: '1.1rem', md: '1.1rem' },
                              fontWeight: 600,
                              color: 'rgba(0, 0, 0, 0.87)'
                            }}
                          >
                            {driver.driverName}
                          </Typography>
                          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                            <DriverSummary driver={driver} />
                          </Box>
                        </Box>
                      )}
                    >
                      {(item) => (
                        <>
                          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                            <DriverSummary driver={item} isMobile />
                          </Box>
                          <ComplaintsTable
                            complaints={item.complaints}
                            sortField={sortField}
                            sortOrder={sortOrder}
                            onSort={handleSort}
                          />
                        </>
                      )}
                    </CustomAccordion>
                    <CustomPagination
                      currentPage={params.page}
                      totalPages={complaintsData?.totalPages}
                      onPageChange={(value) => onChangeParams({ ...params, page: value })}
                    />
                  </>
                ) : (
                  <NoDataMessage>
                    <h3>Não há dados para exibir :(</h3>
                    <p>Verifique os filtros aplicados e tente novamente.</p>
                  </NoDataMessage>
                )}
              </Box>
            )}
          </MainContainer>
        </PageContainer>
      </div>
    </Container>
  );
};