import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DriverName, StyledTableHead } from "../../styles";
import NoDataMessage from "components/DataNotFound";
import { Skeleton } from "@mui/material";

interface PunctualityTableProps {
  data: any[];
  loading: boolean;
  searchParams: URLSearchParams;
}

export const PunctualityTable = ({ data, loading, searchParams }: PunctualityTableProps) => {
  const navigate = useNavigate();

  if (loading) {
    return <Skeleton variant="rectangular" width="100%" height={585} />;
  }

  if (data.length === 0) {
    return <NoDataMessage message="Nenhum dado de pontualidade disponível para o período selecionado" />;
  }

  return (
    <Table>
      <StyledTableHead>
        <TableRow>
          <TableCell>Posição</TableCell>
          <TableCell>Nome do Motorista</TableCell>
          <TableCell>Qtd. de entregas</TableCell>
          <TableCell>Atrasos</TableCell>
          <TableCell>Pontualidade (%)</TableCell>
        </TableRow>
      </StyledTableHead>
      <TableBody>
        {data?.map((driver, index) => (
          <TableRow key={index}>
            <TableCell>{driver?.position}</TableCell>
            <TableCell>
              <DriverName
                onClick={() => {
                  const newParams = new URLSearchParams(searchParams);
                  newParams.set("search", driver.name);
                  navigate(`/driver-punctuality?${newParams.toString()}`);
                }}
              >
                {driver?.name}
              </DriverName>
            </TableCell>
            <TableCell>{driver?.total}</TableCell>
            <TableCell>{driver?.totalDelays}</TableCell>
            <TableCell>{driver?.punctuality}%</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}; 