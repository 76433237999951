import { Radio, FormControlLabel } from "@mui/material";
import { RadioLabel } from "./styles";

interface IRadio {
  text?: string;
  value: string;
  checked: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  align?: string;
  size?: "medium" | "small";
  disabled?: boolean;
}

const CustomRadioButton = ({ text, value, checked, handleChange, align, size, disabled }: IRadio) => {
  return (
    <RadioLabel justifyContent={align}>
      <div className="radio-container">
        <FormControlLabel
          value={value}
          control={<Radio checked={checked} onChange={handleChange} size={size || "small"} disabled={disabled} />}
          label={text}
          labelPlacement="start"
        />
      </div>
    </RadioLabel>
  );
};


export default CustomRadioButton;
